import React from 'react'
import PropTypes from 'prop-types'

import styles from './Card.module.css'

const Card = React.forwardRef(({ as, children, className, ...props }, ref) => {
  const Tag = as

  return (
    <Tag ref={ref} className={[className, styles.card].join(' ')} {...props}>
      {children}
    </Tag>
  )
})

Card.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Card.defaultProps = {
  as: 'div',
  className: '',
}

export default Card
