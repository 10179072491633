import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import Vimeo from '@vimeo/player'

import styles from './ResponsiveVideo.module.css'

export default function ResponsiveVideo({
  vimeoId,
  title,
  width,
  height,
  style,
  quality,
  lazyLoad,
  className,
}) {
  const [hasMounted, setHasMounted] = useState(!lazyLoad)
  const vimeoPlayer = useRef()

  const handleRefChange = useCallback(node => {
    if (node) {
      vimeoPlayer.current = new Vimeo(node)
    } else {
      vimeoPlayer.current = null
    }
  }, [])

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    rootMargin: '0px 0px 2000px',
  })

  useEffect(() => {
    if (inView && !hasMounted && lazyLoad) {
      setHasMounted(true)
    }
  }, [inView, hasMounted, lazyLoad])

  useEffect(() => {
    if (!vimeoPlayer.current) return

    if (inView) {
      vimeoPlayer.current.play()
      // console.log('playing', vimeoId)
    } else {
      vimeoPlayer.current.pause()
      // console.log('pausing', vimeoId)
    }
  }, [inView])

  const mergedStyle = {
    paddingBottom: `${(height / width) * 100}%`,
    ...style,
  }

  const classes = [styles.container, className]

  return (
    <div className={classes.join(' ')} style={mergedStyle} ref={inViewRef}>
      {hasMounted && (
        <iframe
          ref={handleRefChange}
          className={styles.iframe}
          src={`https://player.vimeo.com/video/${vimeoId}?background=1&autopause=0${
            quality ? `&quality=${quality}` : ''
          }`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title={title}
        />
      )}
    </div>
  )
}

ResponsiveVideo.propTypes = {
  vimeoId: PropTypes.string.isRequired,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
  quality: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  lazyLoad: PropTypes.bool,
}

ResponsiveVideo.defaultProps = {
  title: '',
  height: 9,
  width: 16,
  style: {},
  quality: null,
  className: '',
  lazyLoad: true,
}
