import React from 'react'

import styles from './Switch.module.css'

export default function Switch({ onChange, isOn, className, ...props }) {
  return (
    <button
      aria-checked={isOn}
      aria-label="Dark Mode"
      className={[styles.slot, className].join(' ')}
      role="switch"
      type="button"
      {...props}
    >
      <span className={styles.knob} />
    </button>
  )
}
